import React from "react"
import { Link } from "gatsby"
// import { ThemeToggler } from "gatsby-plugin-dark-mode"
// import { scale } from "../utils/typography"

import Footer from "./footer"
import "./global.css"
let nbsp = <span>&nbsp;</span>

const Layout = ({
  location,
  title,
  children,
  isHome,
  showSidebar,
  currPage,
}) => {
  const isAboutPage = currPage === "About"
  const isArticlesPage = currPage === "Blog"
  let articlesHeader = (
    <Link className="nav-link" to={`/`}>
      {"["}
      {nbsp}
      {nbsp}
      {nbsp}
      {"] Blog"}
    </Link>
  )
  if (isArticlesPage) {
    articlesHeader = (
      <p className="non-nav-link" to={`/`}>
        {"[✘] Blog"}
      </p>
    )
  }
  // ↳➡
  let aboutPageHeader = (
    <Link className="nav-link" to={`/about`}>
      {"["}
      {nbsp}
      {nbsp}
      {nbsp}
      {"] About"}
    </Link>
  )
  if (isAboutPage) {
    aboutPageHeader = (
      <p className="non-nav-link" to={`/`}>
        {"[✘] About"}
      </p>
    )
  }
  const header = (
    <>
      <h2 className="nav-links-container">
        <span>{articlesHeader}</span>

        <span className="nav-dot">&nbsp;&nbsp;&nbsp;&nbsp;</span>

        <span>{aboutPageHeader}</span>
      </h2>
    </>
  )
  let mainContentClasses = ["main-content-no-sidebar", "relative"]
  if (showSidebar) {
    mainContentClasses = ["main-content-with-sidebar", "relative"]
  }

  const Button = props => <button className="all-articles-button" {...props} />

  // const backHomeButton = (
  //   <Link to="/">
  //     <Button>All Articles</Button>
  //   </Link>
  // )

  const mainContentClassesStr = mainContentClasses.join(" ")

  return (
    <div
      style={{
        backgroundColor: "var(--bg)",
        color: "var(--textNormal)",
        transition: "color 0.2s ease-out, background 0.2s ease-out",
        minHeight: "100vh",
      }}
    >
      {showSidebar && (
        <div className="sidebar">
          <div className="p-4 flex flex-col justify-center items-center sidebar-container">
            {header}
          </div>
        </div>
      )}

      {/* {showSidebar || backHomeButton} */}

      <div className={mainContentClassesStr}>
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
