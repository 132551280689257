import React from "react"

const Footer = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return (
    // <footer className="my-12 text-center">
    <footer className="my-footer">
      {/* James Shapiro ({new Date().getFullYear()}) */}
    </footer>
  )
}

export default Footer
