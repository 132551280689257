// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

type PageContext = {
  currentPage: number
  mdxPages: number
}
type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMdx: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        slug: string
      }
    }[]
  }
  madscientist: {
    childImageSharp: {
      fixed: {
        src: string
      }
    }
  }
  cloudcomputer: {
    childImageSharp: {
      fixed: {
        src: string
      }
    }
  }
  elephant: {
    childImageSharp: {
      fixed: {
        src: string
      }
    }
  }
  moneyprinter: {
    childImageSharp: {
      fixed: {
        src: string
      }
    }
  }
  origamirabbit: {
    childImageSharp: {
      fixed: {
        src: string
      }
    }
  }
  money: {
    childImageSharp: {
      fixed: {
        src: string
      }
    }
  }
}

const BlogIndex = ({
  data,
  location,
  pageContext,
}: PageProps<Data, PageContext>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const { currentPage, mdxPages } = pageContext

  const isFirst = currentPage === 1
  const isLast = currentPage === mdxPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  const spaceBetweenImages = '8px'
  // const imgBorder = '1px solid #5A7F9A'
   const imgBorder = '1px solid #DDE7F0'

  return (
    <Layout
      location={location}
      title="James"
      isHome={true}
      showSidebar={false}
      currPage={"Blog"}
    >
      <SEO title="Home" />
      <div className="content-container">
        <div className="homepage-about-container">
          <Link to="/about" className="homepage-about-link">About</Link>
        </div>
        <h1 className="index-header">James Shapiro</h1>

        {/* HOMEPAGE MAIN CONTENT */}
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '0 auto' }}>
            <figure 
              style={{ textAlign: 'center', marginRight: spaceBetweenImages }}
            >
              <div className="frontpage-icon"  
                style={{
                  border: imgBorder,
                  borderRadius: '2px',
                  overflow: 'hidden',
                }}>
                <Img
                  fixed={data.cloudcomputer.childImageSharp.fixed}
                  alt={"Cloud Computer"}
                  style={{
                    minWidth: 50,
                    verticalAlign: 'bottom',
                  }}
                />
              </div>
            
            <figcaption style={{ fontWeight: 'bold' }}>Cloud Computing</figcaption>
            </figure>
            {/* <figure style={{ textAlign: 'center', marginRight: spaceBetweenImages }}>
              <div className="frontpage-icon"  
                style={{
                  border: imgBorder,
                  borderRadius: '2px',
                  overflow: 'hidden',
                }}>
                <Img
                  fixed={data.elephant.childImageSharp.fixed}
                  alt={"Elephant Teapot"}
                  style={{
                    minWidth: 50,
                    verticalAlign: 'bottom',
                  }}
                />

              </div>
            
            <figcaption style={{ fontWeight: 'bold' }}>Finer Things</figcaption>
            </figure> */}

            {/* ORIGAMI RABBIT */}
            <figure style={{ textAlign: 'center', marginRight: spaceBetweenImages  }}>
              <div className="frontpage-icon"  
                style={{
                border: imgBorder,
                borderRadius: '2px',
                overflow: 'hidden',
              }}>
                <Img
                  fixed={data.origamirabbit.childImageSharp.fixed}
                  alt={"Origami Rabbit"}
                  style={{
                    minWidth: 50,
                    verticalAlign: 'bottom',
                  }}
                />
              </div>
            
            <figcaption style={{ fontWeight: 'bold' }}>Aesthetics</figcaption>
            </figure>


            {/* MONEY */}
            <figure style={{ textAlign: 'center', marginRight: spaceBetweenImages }}>
              <div className="frontpage-icon"  
                style={{
                  border: imgBorder,
                  borderRadius: '2px',
                  overflow: 'hidden',
                }}>
                <Img
                  fixed={data.money.childImageSharp.fixed}
                  alt={"Money"}
                  style={{
                    minWidth: 50,
                    verticalAlign: 'bottom',
                  }}
                />

              </div>
            
            <figcaption style={{ fontWeight: 'bold' }}>Money</figcaption>
            </figure>



            {/* MAD SCIENTIST */}
            <figure style={{ textAlign: 'center'}}>
              <div className="frontpage-icon"  
                style={{
                border: imgBorder,
                borderRadius: '2px',
                overflow: 'hidden',
              }}>
                <Img
                  fixed={data.madscientist.childImageSharp.fixed}
                  alt={"Mad Scientist"}
                  style={{
                    minWidth: 50,
                    verticalAlign: 'bottom',
                  }}
                />
              </div>
            
            <figcaption style={{ fontWeight: 'bold' }}>Experiments</figcaption>
            </figure>
            
          </div>
          <div style={{ maxWidth: '1040px', margin: '0 auto', marginTop: '20px' }}>
            <h1 className="blog-posts-header">Blog Posts</h1>

            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.slug
              return (
                <article key={node.slug}>
                  <header>
                    <h4
                      style={{
                        fontFamily: "system-ui",
                        fontWeight: "bolder",
                        fontSize: "18px",
                        marginBottom: "20px",
                      }}
                    >
                      <Link
                        style={{
                          boxShadow: `none`,
                          textDecoration: `none`,
                          color: `inherit`,
                        }}
                        to={`/posts/${node.slug}`.slice(0, -1)}
                      >
                        {title}
                      </Link>{" "}
                      <br />
                      <small style={{ color: "#656565" }}>
                        {node.frontmatter.date}
                      </small>
                    </h4>
                  </header>
                </article>
              )
            })}
          </div>
          
        </div>
        
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  ← Previous Page
                </Link>
              )}
            </li>
            <li>
              {!isLast && (
                <Link to={nextPage} rel="next">
                  Next Page →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
      
      <div className="slanted-divider grey-dropshadow"></div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQueryMdx($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    madscientist: file(absolutePath: { regex: "/madscientist.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cloudcomputer: file(absolutePath: { regex: "/cloudcomputer5.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    moneyprinter: file(absolutePath: { regex: "/moneyprinter.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    origamirabbit: file(absolutePath: { regex: "/origamirabbit.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    elephant: file(absolutePath: { regex: "/elephant-teapot.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    philosophers: file(absolutePath: { regex: "/philosophers.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    money: file(absolutePath: { regex: "/wealth2.png/" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
